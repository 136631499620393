
// Импортируем другие js-файлы
$( document ).ready(function() {

    // Knowledgebase content review

    $('.content-review .actions a').on('click', function (){
        $('.questions').hide();
        $('.result-negative').show();
    })

    jQuery(document).ready(function(){
        jQuery(document).on('nfFormSubmitResponse', function(event, response, id){
            if(response.id == '20'){
                $('.questions').hide();
                $('.result-positive').show();
            } else if(response.id == '21'){
                $('.questions').hide();
                $('.result-negative').hide();
                $('.result-last').show();
            }
        });
    });

    // Business header nav

    $('.header-business .main-bar .menu-item-has-children').mouseenter(function() {
        $(this).addClass('active');
        $(this).find('.sub-menu').addClass('active');
    }).mouseleave(function() {
        $(this).removeClass('active');
        $(this).find('.sub-menu').removeClass('active');
    });

    $('.header-business .bar .menu-item-has-children').mouseenter(function() {
        $(this).addClass('active');
        $(this).find('.sub-menu').addClass('active');
    }).mouseleave(function() {
        $(this).removeClass('active');
        $(this).find('.sub-menu').removeClass('active');
    });

    // Business header sticky

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 90) {
            $(".header-business").addClass("sticky");
        } else {
            $(".header-business").removeClass("sticky");
        }
    });

    // Business mobile main bar

    $('.main-bar .hamburger a').on('click', function (){
       $('.header-business').toggleClass('active');
    });

    // Business mobile bar

    $('.bar h3.show-mobile').on('click', function (){
        $('.bar').toggleClass('active');
    });

    // Business tabs next/prev

    $('.next').click(function(){
        event.preventDefault();
        $('.nav li .active').parent().next('li').find('a').trigger('click');
        $('.nav li').hide();

        if ($(".nav li a").hasClass("active")) {
            $(".nav li .active").parent().show();
            $(".nav li .active").parent().next('li').show();
        }
    });

    $('.prev').click(function(){
        event.preventDefault();
        $('.nav li .active').parent().prev('li').find('a').trigger('click');
        $('.nav li').hide();

        if ($(".nav li a").hasClass("active")) {
            $(".nav li .active").parent().show();
            $(".nav li .active").parent().prev('li').show();
        }
    });

    if ($(".business-tabs").length > 0){

        // Business tabs sticky

        var element_position = $('.business-tabs').offset().top + 30;

        $(window).on('scroll', function() {
            var y_scroll_pos = window.pageYOffset;
            var scroll_pos_test = element_position;

            if(y_scroll_pos > scroll_pos_test) {
                $('.business-tabs .nav').addClass('sticky');
            } else {
                $('.business-tabs .nav').removeClass('sticky');
            }
        });

        // slide to top of the page
        $(".business-tabs .nav li a").click(function() {
            $('html, body').animate({
                scrollTop: $(".business-tabs").offset().top
            }, 600);
        });
    }


    //
    // document.addEventListener( 'wpcf7mailsent', function( event ) {
    //     if ( '8658' == event.detail.contactFormId ) {
    //         $('.questions').hide();
    //         $('.result-negative').hide();
    //         $('.result-last').show();
    //     } else if ( '8657' == event.detail.contactFormId ) {
    //         $('.questions').hide();
    //         $('.result-positive').show();
    //     }
    // }, false );

    // Cookie header banner

    cookiesPolicyBar();

    function cookiesPolicyBar(){
        // Check cookie
        if ($.cookie('yourCookieName') != "active") $('#popup-banner').show();
        if ($.cookie('yourCookieName') == "active") $('#popup-banner').hide();
        //Assign cookie on click
        $('#btn-close').on('click',function(){
            $.cookie('yourCookieName', 'active', { path: 1 }); // cookie will expire in one day
            $('#popup-banner').fadeOut();
        });
    }

    // Software item open/close

    $('.software-list .item .more').on('click', function (){
        $('.software-list .item').removeClass('open');
        $(this).parent().addClass('open');
    });

    // Add bg to header

    if ($(".wizard")[0]){
        $('.header-navigation').addClass('bg');
    } else {
        // Do something if class does not exist
    }

    // Sidebar hamburger

    $('.sidebar-hamburger').on('click', function (){
        $('.knowledgebase-content .sidebar').toggleClass('active');
    });

    // Remove header once footer reach

    // $(window).on('scroll', function() {
    //     $(".footer").each(function() {
    //         if (isScrolledIntoView($(this))) {
    //             $('.header').addClass("fadeOut");
    //         } else {
    //             $('.header').removeClass("fadeOut");
    //         }
    //     });
    // });
    //
    // function isScrolledIntoView(elem) {
    //     var docViewTop = $(window).scrollTop();
    //     var docViewBottom = docViewTop + $(window).height();
    //
    //     var elemTop = $(elem).offset().top;
    //     var elemBottom = elemTop + $(elem).height();
    //
    //     return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    // }

    // Wizard toggle

    $('.wizard .item-btn').on('click', function (){
        $(this).parent().toggleClass('active');
    });

    // Truncate

    const ReadMore = (() => {
        let s;

        return {

            settings() {
                return {
                    content: document.querySelectorAll('.js-read-more'),
                    originalContentArr: [],
                    truncatedContentArr: [],
                    moreLink: "סגור",
                    lessLink: "קטן יותר",
                }
            },

            init() {
                s = this.settings();
                this.bindEvents();
            },

            bindEvents() {
                ReadMore.truncateText();
            },

            /**
             * Count Words
             * Helper to handle word count.
             * @param {string} str - Target content string.
             */
            countWords(str) {
                return str.split(/\s+/).length;
            },

            /**
             * Ellpise Content
             * @param {string} str - content string.
             * @param {number} wordsNum - Number of words to show before truncation.
             */
            ellipseContent(str, wordsNum) {
                return str.split(/\s+/).slice(0, wordsNum).join(' ') + '...';
            },

            /**
             * Truncate Text
             * Truncate and ellipses contented content
             * based on specified word count.
             * Calls createLink() and handleClick() methods.
             */
            truncateText() {

                for (let i = 0; i < s.content.length; i++) {
                    //console.log(s.content)
                    const originalContent = s.content[i].innerHTML;
                    const numberOfWords = s.content[i].dataset.rmWords;
                    const truncateContent = ReadMore.ellipseContent(originalContent, numberOfWords);
                    const originalContentWords = ReadMore.countWords(originalContent);

                    s.originalContentArr.push(originalContent);
                    s.truncatedContentArr.push(truncateContent);

                    if (numberOfWords < originalContentWords) {
                        s.content[i].innerHTML = s.truncatedContentArr[i];
                        let self = i;
                        ReadMore.createLink(self)
                    }
                }
                ReadMore.handleClick(s.content);
            },

            /**
             * Create Link
             * Creates and Inserts Read More Link
             * @param {number} index - index reference of looped item
             */
            createLink(index) {
                const linkWrap = document.createElement('span');

                linkWrap.className = 'read-more__link-wrap';

                linkWrap.innerHTML = `<a id="read-more_${index}"
                                class="read-more__link"
                                style="cursor:pointer;">
                                ${s.moreLink}
                            </a>`;

                // Inset created link
                s.content[index].parentNode.insertBefore(linkWrap, s.content[index].nextSibling);

            },

            /**
             * Handle Click
             * Toggle Click eve
             */
            handleClick(el) {
                const readMoreLink = document.querySelectorAll('.read-more__link');

                for (let j = 0, l = readMoreLink.length; j < l; j++) {

                    readMoreLink[j].addEventListener('click', function() {

                        const moreLinkID = this.getAttribute('id');
                        let index = moreLinkID.split('_')[1];

                        el[index].classList.toggle('is-expanded');

                        if (this.dataset.clicked !== 'true') {
                            el[index].innerHTML = s.originalContentArr[index];
                            this.innerHTML = s.lessLink;
                            this.dataset.clicked = true;
                        } else {
                            el[index].innerHTML = s.truncatedContentArr[index];
                            this.innerHTML = s.moreLink;
                            this.dataset.clicked = false;
                        }
                    });
                }
            },

            /**
             * Open All
             * Method to expand all instances on the page.
             * Will probably be useful with a destroy method.
             */
            openAll() {
                const instances = document.querySelectorAll('.read-more__link');
                for (let i = 0; i < instances.length; i++) {
                    content[i].innerHTML = s.truncatedContentArr[i];
                    instances[i].innerHTML = s.moreLink;
                }
            }
        }
    })();

    ReadMore.init();

    // Knowledgebase open/close

    $('.knowledgebase-content .content-wrapper .item .subcat-name').on('click', function (){
        $('.knowledgebase-content .content-wrapper .item .subcat-name').not(this).removeClass('active');
        $('.knowledgebase-content .content-wrapper .item .subcat-name').not(this).parent().find('.list').hide();
        $(this).toggleClass('active');
        $(this).parent().find('.list').toggle();

        $('html, body').animate({
            scrollTop: $(this).offset().top - 125
        }, 800);
    });

    // Knowledgebase hash

    $(function() {
        let hash = window.location.hash;

        $('.subcat-name[href="' + hash + '"]').trigger( "click" );
        console.log(hash);
    });

    // Page 404

    $('.open-info').on('click', function (){
        $('.page-404 .wrapper .item').hide();
        $('.page-404 .wrapper .info').show();
    })

    // Contact form redirect

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        if ( '1164' == event.detail.contactFormId ) {
            window.location.replace("/thank-you/");
        }
        if ( '304' == event.detail.contactFormId ) {
            window.location.replace("/thank-you/");
        }
        if ( '303' == event.detail.contactFormId ) {
            window.location.replace("/thank-you/");
        }
        if ( '302' == event.detail.contactFormId ) {
            window.location.replace("/thank-you/");
        }
        if ( '301' == event.detail.contactFormId ) {
            window.location.replace("/thank-you/");
        }
    }, false );

    // Knowledgebase search words

    $('.form-additional a').on('click', function (event){
        var word = $(this).text();
        var search = $('.sf-input-text');
        search.val(word).focus().keypress();
    })
});
$( document ).ready(function() {

    var controller = new ScrollMagic.Controller();

    // Parallax element 11

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-11", 2, {scale: 1, top: 58}, {top: -58, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".business-manage",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 12

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-12", 2, {scale: 1, top: 0}, {top: -150, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".home-testimonials",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);
});